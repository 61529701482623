import { Link } from 'react-router-dom'
import { AppBar, Button, Toolbar } from '@mui/material'
import { blueGrey } from '@mui/material/colors'

const navLinks = [
  {
    target: '/products',
    label: 'Products',
  },
  {
    target: '/settings',
    label: 'Settings',
  },
  {
    target: '/status',
    label: 'Status',
  },
]

export const Navbar = () => {
  return (
    <AppBar position="static" style={{ listStyle: 'none', display: 'flex' }}>
      <Toolbar>
        {navLinks.map(link => (
          <Link key={link.target} to={link.target}>
            <Button sx={{ color: blueGrey[50] }}>{link.label}</Button>
          </Link>
        ))}
      </Toolbar>
    </AppBar>
  )
}
