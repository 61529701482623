import { useEffect, useState, FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useManualQuery } from 'graphql-hooks'
import {
  Box,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { ProductTable, ProductTableProduct } from '../components'
import { ProductOrigin, QueryProductsArgs } from '../types'
import { GraphQLState } from '../types/GraphQLState'
import { slugify, screamify } from '../util'

export const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState(searchParams.get('term'))
  const [origin, setOrigin] = useState<ProductOrigin>(
    (screamify(searchParams.get('origin') || '') as ProductOrigin) ||
      ProductOrigin.ActiveE
  )
  const [variables, setVariables] = useState<
    Pick<QueryProductsArgs, 'origin' | 'page' | 'term'>
  >({
    origin,
    term: searchTerm,
  })
  const [state, setState] = useState<GraphQLState>(GraphQLState.INIT)
  const [products, setProducts] = useState<ProductTableProduct[]>([])
  const [getActiveEProducts] = useManualQuery<
    { products: ProductTableProduct[] },
    Pick<QueryProductsArgs, 'origin' | 'page' | 'term'>
  >(`${GET_PRODUCTS}${ACTIVE_E_FIELDS}`)
  const [getWooProducts] = useManualQuery<
    { products: ProductTableProduct[] },
    Pick<QueryProductsArgs, 'origin' | 'page' | 'term'>
  >(`${GET_PRODUCTS}${WOO_FIELDS}`)
  const [getReverbProducts] = useManualQuery<
    { products: ProductTableProduct[] },
    Pick<QueryProductsArgs, 'origin' | 'page' | 'term'>
  >(`${GET_PRODUCTS}${REVERB_FIELDS}`)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVariables(variables => ({ ...variables, term: searchTerm }))
    }, 800)

    return () => {
      clearTimeout(timeout)
    }
  }, [searchTerm])

  useEffect(() => {
    setVariables(variables => ({ ...variables, origin }))
  }, [origin])

  useEffect(() => {
    setState(GraphQLState.LOADING)
    setSearchParams(
      {
        origin: slugify(variables.origin),
        term: variables.term || '',
      },
      { replace: true }
    )
    let fetcher
    switch (variables.origin) {
      case ProductOrigin.ActiveE:
        fetcher = getActiveEProducts
        break
      case ProductOrigin.Woocommerce:
        fetcher = getWooProducts
        break
      case ProductOrigin.Reverb:
        fetcher = getReverbProducts
        break
      default:
    }
    if (!fetcher) return

    const controller = new AbortController()
    fetcher({
      variables,
      fetchOptionsOverrides: {
        signal: controller.signal,
      },
    }).then(({ data, error }) => {
      if (error) {
        setState(GraphQLState.ERROR)
      } else if (data) {
        setState(GraphQLState.READY)
        setProducts(data.products)
      }
    })

    return () => {
      controller.abort()
    }
  }, [
    getActiveEProducts,
    getWooProducts,
    getReverbProducts,
    variables,
    setSearchParams,
  ])

  return (
    <Box>
      <Box
        component="form"
        onSubmit={(e: FormEvent) => e.preventDefault()}
        sx={{ mb: 2, display: 'flex', gap: 3 }}
      >
        <FormGroup sx={{ flex: '4 1 auto' }}>
          <TextField
            variant="filled"
            label="Search"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </FormGroup>
        <FormGroup sx={{ flex: '1 1 auto' }}>
          <Select
            value={origin}
            onChange={e => setOrigin(e.target.value as ProductOrigin)}
          >
            <MenuItem value={ProductOrigin.ActiveE}>Active-e</MenuItem>
            <MenuItem value={ProductOrigin.Woocommerce}>WooCommerce</MenuItem>
            <MenuItem value={ProductOrigin.Reverb}>Reverb</MenuItem>
          </Select>
        </FormGroup>
      </Box>
      {state === GraphQLState.LOADING && <Typography>Loading...</Typography>}
      {state === GraphQLState.ERROR && (
        <Typography>An error occurred while fetching products.</Typography>
      )}
      {state === GraphQLState.READY &&
        (products.length ? (
          <ProductTable products={products} />
        ) : (
          <Typography>No products found.</Typography>
        ))}
    </Box>
  )
}

const GET_PRODUCTS = `
  query ($origin: ProductOrigin! $page: PageQuery $term: String) {
    products(
      origin: $origin
      page: $page
      term: $term
    ) {
      id
      name
      origin
      type
      ...originSpecificFields
    }
  }
`

const ACTIVE_E_FIELDS = `
  fragment originSpecificFields on Product {
    ...on ActiveESimpleProduct {
      qtyAvailable
    }
    ...on ActiveEVariableProduct {
      variants {
        sku
        qtyAvailable
      }
    }
  }
`

const WOO_FIELDS = `
  fragment originSpecificFields on Product {
    ...on WooCommerceSimpleProduct {
      qtyAvailable
    }
    ...on WooCommercePackageProduct {
      qtyAvailable
    }
  }
`

const REVERB_FIELDS = `
  fragment originSpecificFields on Product {
    ...on ReverbSimpleProduct {
      qtyAvailable
    }
  }
`
