import { FormEvent, useCallback, useState } from 'react'
import { useMutation } from 'graphql-hooks'
import { Box, Button, Typography } from '@mui/material'
import {
  ProductType,
  PublishWooCommerceProductPayload,
  SynchronizeQuantityPayload,
  UnpublishWooCommerceProductPayload,
  WooCommerceProduct,
  WooCommerceProductStatus,
  WooCommerceVariant,
} from '../types'

interface WooCommerceSimpleProductActionsProps {
  type: ProductType.Simple
  product: Pick<
    WooCommerceProduct,
    'id' | 'status' | 'stockStatus' | 'qtyAvailable'
  >
  variant?: never
}

interface WooCommerceVariableProductActionsProps {
  type: ProductType.Variable
  product: Pick<WooCommerceProduct, 'id'>
  variant: Pick<
    WooCommerceVariant,
    'id' | 'qtyAvailable' | 'status' | 'stockStatus'
  >
}

interface WooCommercePackageProductActionsProps {
  type: ProductType.Package
  product: Pick<
    WooCommerceProduct,
    'id' | 'status' | 'stockStatus' | 'qtyAvailable'
  >
  variant?: never
}

type WooCommerceProductActionsProps =
  | WooCommerceSimpleProductActionsProps
  | WooCommerceVariableProductActionsProps
  | WooCommercePackageProductActionsProps

export const WooCommerceProductActions = ({
  type,
  product,
  variant,
}: WooCommerceProductActionsProps) => {
  const [status, setStatus] = useState<WooCommerceProductStatus>(
    variant ? variant.status : product.status
  )
  const [publishProduct] = useMutation<{
    publishWooCommerceProduct: PublishWooCommerceProductPayload
  }>(PUBLISH_PRODUCT, {
    variables: {
      id: parseInt(product.id),
      variantId: variant ? parseInt(variant.id) : null,
    },
  })
  const [unpublishProduct] = useMutation<{
    unpublishWooCommerceProduct: UnpublishWooCommerceProductPayload
  }>(UNPUBLISH_PRODUCT, {
    variables: {
      id: parseInt(product.id),
      variantId: variant ? parseInt(variant.id) : null,
    },
  })

  const sendUnpublishRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const { data } = await unpublishProduct()
      if (data?.unpublishWooCommerceProduct.success) {
        setStatus(WooCommerceProductStatus.Draft)
      }
    },
    [unpublishProduct]
  )

  const sendPublishRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const { data } = await publishProduct()
      if (data?.publishWooCommerceProduct.success) {
        setStatus(WooCommerceProductStatus.Published)
      }
    },
    [publishProduct]
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {(type === ProductType.Simple || type === ProductType.Package) && (
        <WooProductQtyAdjustForm
          productId={parseInt(product.id)}
          type={type}
          initialQty={product.qtyAvailable || 0}
        />
      )}
      {type === ProductType.Variable && (
        <WooVariantQtyAdjustForm
          initialQty={variant.qtyAvailable || 0}
          productId={parseInt(product.id)}
          variantId={parseInt(variant.id)}
        />
      )}
      {status === WooCommerceProductStatus.Published ? (
        <form onSubmit={sendUnpublishRequest}>
          <Button type="submit" variant="outlined" sx={{ flex: '0 2 150px' }}>
            Unpublish
          </Button>
        </form>
      ) : (
        <form onSubmit={sendPublishRequest}>
          <Button type="submit" variant="outlined" sx={{ flex: '0 2 150px' }}>
            Publish
          </Button>
        </form>
      )}
    </Box>
  )
}

export type WooProductQtyAdjustFormProps = {
  productId: number
  type: ProductType
  initialQty: number
}

export const WooProductQtyAdjustForm = ({
  productId,
  type,
  initialQty,
}: WooProductQtyAdjustFormProps) => {
  const [qty, setQty] = useState(initialQty)
  const [syncQty] = useMutation<
    { synchronizeQuantity: SynchronizeQuantityPayload },
    { id: number }
  >(SYNC_PRODUCT_QTY)

  const sendUpdateRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const { data } = await syncQty({ variables: { id: productId } })

      if (data?.synchronizeQuantity.success) {
        setQty(data.synchronizeQuantity.quantity as number)
      }
    },
    [productId, syncQty]
  )

  return (
    <Box
      component="form"
      onSubmit={sendUpdateRequest}
      sx={{ display: 'flex', gap: 2, alignItems: 'baseline' }}
    >
      <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
        Qty. Available: <strong>{qty}</strong>
      </Typography>
      {type !== ProductType.Package && (
        <Button type="submit" variant="contained" sx={{ flex: '0 2 150px' }}>
          Update Qty
        </Button>
      )}
    </Box>
  )
}

export type WooVariantQtyAdjustFormProps = {
  productId: number
  variantId: number
  initialQty: number
}

export const WooVariantQtyAdjustForm = ({
  productId,
  variantId,
  initialQty,
}: WooVariantQtyAdjustFormProps) => {
  const [qty, setQty] = useState(initialQty)
  const [syncQty] = useMutation<
    { synchronizeQuantity: SynchronizeQuantityPayload },
    { id: number; variantId: number }
  >(SYNC_VARIANT_QTY)

  const sendUpdateRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const { data } = await syncQty({
        variables: {
          id: productId,
          variantId,
        },
      })

      if (data?.synchronizeQuantity.success) {
        setQty(data.synchronizeQuantity.quantity as number)
      }
    },
    [productId, variantId, syncQty]
  )

  return (
    <Box
      component="form"
      onSubmit={sendUpdateRequest}
      sx={{ display: 'flex', gap: 2, alignItems: 'baseline' }}
    >
      <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
        Qty. Available: <strong>{qty}</strong>
      </Typography>
      <Button type="submit" variant="contained" sx={{ flex: '0 2 150px' }}>
        Update Qty
      </Button>
    </Box>
  )
}

const SYNC_PRODUCT_QTY = `
  mutation ($id: ID!) {
    synchronizeQuantity (
      id: $id
      origin: WOOCOMMERCE
    ) {
      success
      quantity
    }
  }
`

const SYNC_VARIANT_QTY = `
  mutation ($id: ID! $variantId: ID!) {
    synchronizeQuantity (
      id: $id
      variantId: $variantId
      origin: WOOCOMMERCE
    ) {
      success
      quantity
    }
  }
`

const PUBLISH_PRODUCT = `
  mutation ($id: ID! $variantId: ID) {
    publishWooCommerceProduct (id: $id variantId: $variantId) {
      success
    }
  }
`

const UNPUBLISH_PRODUCT = `
  mutation ($id: ID! $variantId: ID) {
    unpublishWooCommerceProduct (id: $id variantId: $variantId) {
      success
    }
  }
`
