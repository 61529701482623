import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { NotFound } from '.'
import {
  ActiveEProductDisplay,
  ReverbProductDisplay,
  WooCommerceProductDisplay,
} from '../components'

export const SingleProduct = () => {
  const params = useParams()
  const id = useMemo(() => {
    return params.id ? parseInt(params.id) : null
  }, [params.id])

  if (!id) {
    return <NotFound />
  }

  switch (params.platform) {
    case 'active-e':
      return <ActiveEProductDisplay id={id} />
    case 'woocommerce':
      return <WooCommerceProductDisplay id={id} />
    case 'reverb':
      return <ReverbProductDisplay id={id} />
    default:
      return <NotFound />
  }
}
