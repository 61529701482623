import { useQuery } from 'graphql-hooks'
import { Box, Link, Paper, Typography } from '@mui/material'
import { Link as LinkIcon } from '@mui/icons-material'
import {
  ImageGallery,
  ProductLinks,
  ProductLogList,
  ProductPropList,
  ProductProperty,
  ReverbProductActions,
} from '.'
import { ForeignIds, Log, ProductType, ReverbProduct } from '../types'

export interface ReverbProductProps {
  id: number
}

interface ProductBase
  extends Pick<
    ReverbProduct,
    | 'origin'
    | 'id'
    | 'name'
    | 'url'
    | 'type'
    | 'model'
    | 'sku'
    | 'images'
    | 'status'
    | 'qtyAvailable'
  > {
  foreignIds: Pick<ForeignIds, 'activeE' | 'woocommerce'>
  categories: {
    name: string
  }[]
  logs: {
    nodes: Pick<Log, 'id' | 'message' | 'origin' | 'updatedAt'>[]
  }
}

type ProductQueryProduct =
  | ({ type: ProductType.Simple } & ProductBase)
  | ({ type: ProductType.Serial } & ProductBase)

export const ReverbProductDisplay = ({ id }: ReverbProductProps) => {
  const { data, loading, error } = useQuery<{ product: ProductQueryProduct }>(
    GET_PRODUCT,
    {
      variables: {
        id,
      },
    }
  )

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  if (!data) {
    if (error) {
      console.log(error)
    }
    return <Typography>An error occurred.</Typography>
  }

  return (
    <>
      <Box component={Paper} sx={{ p: 4, mb: 2 }}>
        <Typography variant="h1" sx={{ mb: 0 }}>
          {data.product.name}
        </Typography>
        <Link variant="subtitle1" href={data.product.url} target="_blank">
          <LinkIcon
            style={{ transform: 'translateY(6px) rotate(-45deg) scale(0.8)' }}
          />
          {data.product.url}
        </Link>
        <ProductLinks product={data.product} />
        <Box sx={{ display: 'flex', flexFlow: 'row wrap', '& > *': { p: 2 } }}>
          <Box sx={{ flex: '1 1 200px' }}>
            <ImageGallery
              images={data.product.images.map((image, i) => ({
                id: `image-${i}`,
                src: image.large,
                alt: '',
              }))}
            />
          </Box>
          <Box sx={{ flex: '2 1 400px' }}>
            {data.product.type === ProductType.Simple && (
              <ReverbProductActions
                type={ProductType.Simple}
                product={data.product}
              />
            )}
            <ProductPropList>
              {!!data.product.model && (
                <ProductProperty
                  primary="Model"
                  secondary={data.product.model}
                />
              )}
              <ProductProperty
                primary="Categories"
                secondary={data.product.categories
                  .map(category => category.name)
                  .join()}
              />
            </ProductPropList>
          </Box>
        </Box>
        <ProductLogList logs={data.product.logs.nodes} />
      </Box>
    </>
  )
}

const GET_PRODUCT = `
  query ($id: ID!) {
    product (
      id: $id
      origin: REVERB
    ) {
      origin
      id
      name
      type
      model
      foreignIds {
        activeE
        woocommerce
      }
      logs {
        nodes {
          id
          message
          origin
          updatedAt
        }
      }
      ...on ReverbProduct {
        url
        sku
        categories {
          name
        }
        images {
          large
        }
        price
        qtyAvailable
      }
    }
  }
`
