import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { AppProvider, GraphQLProvider } from './context'
import { Header, Router } from './components'
import { theme } from './theme'

export const App = () => {
  return (
    <GraphQLProvider>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <BrowserRouter>
            <Header />
            <Router />
          </BrowserRouter>
        </AppProvider>
      </ThemeProvider>
    </GraphQLProvider>
  )
}
