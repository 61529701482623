import { Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { Log } from '../types'

export interface ProductLogListProps {
  logs: Pick<Log, 'id' | 'message' | 'origin' | 'updatedAt'>[]
}

export const ProductLogList = ({ logs }: ProductLogListProps) => {
  const rows = useMemo(() => {
    return logs.reduce((acc, log) => {
      const __log = { ...log }
      if (!__log.message) {
        __log.message = '<Empty>'
      }
      acc.push(__log)

      return acc
    }, [] as ProductLogListProps['logs'])
  }, [logs])

  const columns = useMemo(() => {
    const output: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'message', headerName: 'Message', flex: 1 },
      { field: 'origin', headerName: 'Origin', width: 200 },
      { field: 'updatedAt', headerName: 'Updated', width: 200 },
    ]

    return output
  }, [])

  return (
    <>
      <Typography variant="h2" sx={{ mt: 2, mb: 0 }}>
        Logs
      </Typography>
      <div style={{ height: 400 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </>
  )
}
