import { PropsWithChildren } from 'react'
import { ClientContext, GraphQLClient } from 'graphql-hooks'

const client = new GraphQLClient({
  url: '/graphql',
  fetch: async (...args) => {
    try {
      const response = await fetch(...args)

      if (!response.ok) {
        if (response.status === 401) {
          const root = document.getElementById('root')
          root?.dispatchEvent(new Event('expire-login'))

          return new Response('{}')
        }
      }

      return response
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.code === DOMException.ABORT_ERR
      ) {
        return new Response('{}')
      }

      throw error
    }
  },
})

export const GraphQLProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  )
}
