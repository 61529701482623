import { useQuery } from 'graphql-hooks'
import { Box, Link, Paper, Typography } from '@mui/material'
import { Link as LinkIcon } from '@mui/icons-material'
import {
  ImageGallery,
  ProductAttributeList,
  ProductLogList,
  ProductPropList,
  ProductProperty,
} from '.'
import {
  ForeignIds,
  Log,
  ProductType,
  WooCommerceProduct,
  WooCommerceSimpleProduct,
  WooCommerceVariableProduct,
  WooCommerceSerialProduct,
  WooCommerceVariant,
  WooCommerceProductImage,
  WooCommerceProductAttribute,
  WooCommerceVariantAttribute,
  WooCommercePackageProduct,
  PackageItemEdge,
  Product,
} from '../types'
import { ProductLinks } from './product-links'
import { WooCommerceProductActions } from './woocommerce-product-actions'
import { ProductPackageItems } from './product-package-items'

export interface WooCommerceProductProps {
  id: number
}

interface ProductImage
  extends Pick<WooCommerceProductImage, 'id' | 'src' | 'alt'> {}

interface ProductAttribute
  extends Pick<WooCommerceProductAttribute, 'name' | 'options'> {}

interface VariantAttribute
  extends Pick<WooCommerceVariantAttribute, 'name' | 'option'> {}

interface ProductBase
  extends Pick<
    WooCommerceProduct,
    | 'origin'
    | 'id'
    | 'name'
    | 'url'
    | 'type'
    | 'model'
    | 'sku'
    | 'status'
    | 'stockStatus'
  > {
  foreignIds: Pick<ForeignIds, 'activeE' | 'reverb'>
  categories: {
    name: string
  }[]
  images: ProductImage[]
  attributes: ProductAttribute[]
  logs: {
    nodes: Pick<Log, 'id' | 'message' | 'origin' | 'updatedAt'>[]
  }
}

interface SimpleProduct
  extends ProductBase,
    Pick<WooCommerceSimpleProduct, 'qtyAvailable' | 'regularPrice'> {}

interface ProductVariant
  extends Pick<
    WooCommerceVariant,
    'id' | 'sku' | 'qtyAvailable' | 'regularPrice' | 'status' | 'stockStatus'
  > {
  images: ProductImage[]
  attributes: VariantAttribute[]
}

interface VariableProduct
  extends ProductBase,
    Pick<WooCommerceVariableProduct, 'qtyAvailable'> {
  variants: ProductVariant[]
}

interface SerialProduct
  extends ProductBase,
    Pick<WooCommerceSerialProduct, 'stockStatus' | 'serial'> {}

interface PackageProduct
  extends ProductBase,
    Pick<WooCommercePackageProduct, 'qtyAvailable' | 'regularPrice'> {
  children: {
    edges: Array<
      Pick<PackageItemEdge, 'qty'> & {
        node: Pick<Product, 'id' | 'name' | 'sku'>
      }
    >
  }
}

type ProductQueryProduct =
  | ({ type: ProductType.Simple } & SimpleProduct)
  | ({ type: ProductType.Variable } & VariableProduct)
  | ({ type: ProductType.Serial } & SerialProduct)
  | ({ type: ProductType.Package } & PackageProduct)

export const WooCommerceProductDisplay = ({ id }: WooCommerceProductProps) => {
  const { data, loading, error } = useQuery<{ product: ProductQueryProduct }>(
    GET_PRODUCT,
    {
      variables: {
        id,
      },
    }
  )

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  if (!data) {
    if (error) {
      console.log(error)
    }
    return <Typography>An error occurred.</Typography>
  }

  return (
    <>
      <Box component={Paper} sx={{ p: 4, mb: 2 }}>
        <Typography variant="h1" sx={{ mb: 0 }}>
          {data.product.name}
        </Typography>
        <Link variant="subtitle1" href={data.product.url} target="_blank">
          <LinkIcon
            style={{
              transform: 'translateY(6px) rotate(-45deg) scale(0.8)',
            }}
          />
          {data.product.url}
        </Link>
        {data.product.type !== ProductType.Package && (
          <ProductLinks product={data.product} />
        )}
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            '& > *': { p: 2 },
          }}
        >
          <Box sx={{ flex: '1 1 200px' }}>
            <ImageGallery images={data.product.images} />
          </Box>
          <Box sx={{ flex: '2 1 400px' }}>
            {(data.product.type === ProductType.Simple ||
              data.product.type === ProductType.Package) && (
              <WooCommerceProductActions
                type={data.product.type}
                product={data.product}
              />
            )}
            <ProductPropList>
              {!!data.product.model && (
                <ProductProperty
                  primary="Model"
                  secondary={data.product.model}
                />
              )}
              <ProductProperty
                primary="Categories"
                secondary={data.product.categories
                  .map(category => category.name)
                  .join()}
              />
            </ProductPropList>
            {!!data.product.attributes && (
              <ProductAttributeList
                attributes={data.product.attributes.map(attribute => ({
                  name: attribute.name,
                  value: attribute.options.join(', '),
                }))}
              />
            )}
          </Box>
        </Box>
        {data.product.type === ProductType.Package && (
          <ProductPackageItems items={data.product.children.edges} />
        )}
        <ProductLogList logs={data.product.logs.nodes} />
      </Box>
      {data.product.type === ProductType.Variable &&
        data.product.variants.map(variant => (
          <Box key={variant.id} component={Paper} sx={{ p: 4, mb: 2 }}>
            <Typography variant="h2">{variant.sku}</Typography>
            <Box
              sx={{ display: 'flex', flexFlow: 'row wrap', '& > *': { p: 2 } }}
            >
              <Box sx={{ flex: '1 1 200px' }}>
                <ImageGallery images={variant.images} />
              </Box>
              <Box sx={{ flex: '2 1 400px' }}>
                <WooCommerceProductActions
                  type={ProductType.Variable}
                  product={data.product}
                  variant={variant}
                />
                <ProductAttributeList
                  attributes={variant.attributes.map(attribute => ({
                    name: attribute.name,
                    value: attribute.option,
                  }))}
                />
              </Box>
            </Box>
          </Box>
        ))}
    </>
  )
}

const GET_PRODUCT = `
  query ($id: ID!) {
    product (
      id: $id
      origin: WOOCOMMERCE
    ) {
      origin
      id
      name
      type
      model
      foreignIds {
        activeE
        reverb
      }
      logs {
        nodes {
          id
          message
          origin
          updatedAt
        }
      }
      ...on WooCommerceProduct {
        url
        sku
        categories {
          name
        }
        images {
          id
          src
          alt
        }
        attributes {
          name
          options
        }
        status
        stockStatus
        ...on WooCommerceSimpleProduct {
          regularPrice
          qtyAvailable
        }
        ...on WooCommerceVariableProduct {
          qtyAvailable
          variants {
            id
            sku
            qtyAvailable
            regularPrice
            attributes {
              name
              option
            }
            images {
              id
              src
              alt
            }
            status
            stockStatus
          }
        }
        ...on WooCommerceSerialProduct {
          stockStatus
          serial
        }
        ...on WooCommercePackageProduct {
          regularPrice
          qtyAvailable
          children {
            edges {
              qty
              node {
                id
                name
                sku
              }
            }
          }
        }
      }
    }
  }
`
