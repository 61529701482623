import { Box, Chip } from '@mui/material'
import { Link } from 'react-router-dom'
import { ForeignIds, ProductOrigin } from '../types'

export interface ProductLinksProps {
  product: {
    origin: ProductOrigin
    foreignIds: Partial<ForeignIds>
  }
}

export const ProductLinks = ({ product }: ProductLinksProps) => {
  return (
    <Box sx={{ display: 'flex', flexFlow: 'row wrap' }}>
      {product.origin !== ProductOrigin.ActiveE && (
        <Chip
          component={Link}
          label="Active-e"
          to={`/product/active-e/${product.foreignIds.activeE}`}
          clickable
          disabled={!product.foreignIds.activeE}
        />
      )}
      {product.origin !== ProductOrigin.Reverb && (
        <Chip
          component={Link}
          label="Reverb"
          to={`/product/reverb/${product.foreignIds.reverb}`}
          clickable
          disabled={!product.foreignIds.reverb}
        />
      )}
      {product.origin !== ProductOrigin.Woocommerce && (
        <Chip
          component={Link}
          label="WooCommerce"
          to={`/product/woocommerce/${product.foreignIds.woocommerce}`}
          clickable
          disabled={!product.foreignIds.woocommerce}
        />
      )}
    </Box>
  )
}
