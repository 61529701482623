import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
} from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import { ActiveEVariant, Product, ProductType } from '../types'
import { slugify } from '../util'

export type ProductTableProduct = Pick<
  Product,
  'id' | 'name' | 'type' | 'origin'
> &
  (
    | {
        variants: Pick<ActiveEVariant, 'sku' | 'qtyAvailable'>[]
        qtyAvailable: never
      }
    | {
        variants: never
        qtyAvailable: number
      }
    | {
        variants: never
        qtyAvailable: never
      }
  )

export interface ProductTableProps {
  products: ProductTableProduct[]
}

export interface ProductRowProps {
  product: ProductTableProduct
}

export const ProductTable = ({ products }: ProductTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="products table">
        <TableHead>
          <TableRow>
            <TableCell width={60}></TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell align="center" width="120">
              Status
            </TableCell>
            <TableCell align="center" width="80">
              Qty
            </TableCell>
            <TableCell align="center" width="120">
              Type
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(product => (
            <ProductRow key={product.id} product={product} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const ProductRow = ({ product }: ProductRowProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align="center" width={60}>
          {product.variants?.length ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setIsOpen(!isOpen)}
            >
              <KeyboardArrowRight
                style={{
                  transform: `rotate(${isOpen ? 90 : 0}deg)`,
                  transition: 'all 0.2s ease-out',
                }}
              />
            </IconButton>
          ) : (
            <div style={{ height: 34 }} />
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          <Link to={`/product/${slugify(product.origin)}/${product.id}`}>
            {product.name}
          </Link>
        </TableCell>
        <TableCell align="center" width="80">
          {product.type === ProductType.Simple ||
          product.type === ProductType.Package
            ? product.qtyAvailable > 0
              ? 'In Stock'
              : 'Out of Stock'
            : '–'}
        </TableCell>
        <TableCell align="center" width="120">
          {product.type === ProductType.Simple ||
          product.type === ProductType.Package
            ? product.qtyAvailable
            : '–'}
        </TableCell>
        <TableCell align="center" width="120">
          {product.type}
        </TableCell>
      </TableRow>
      {product.variants?.length ? (
        <TableRow>
          <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={5}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Table sx={{ minWidth: '100%' }}>
                <TableBody>
                  {product.variants.map(variant => (
                    <TableRow key={variant.sku}>
                      <TableCell width={60}></TableCell>
                      <TableCell>{variant.sku}</TableCell>
                      <TableCell align="center" width="120">
                        {variant.qtyAvailable > 0 ? 'In Stock' : 'Out of Stock'}
                      </TableCell>
                      <TableCell align="center" width="80">
                        {variant.qtyAvailable}
                      </TableCell>
                      <TableCell width="120"></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
}
