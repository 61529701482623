import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#001659',
    },
    secondary: {
      main: '#efb11e',
    },
    error: {
      main: '#f43636',
    },
    warning: {
      main: '#ff8100',
    },
    info: {
      main: '#21acf3',
    },
  },
  typography: {
    h1: {
      fontSize: '2.488rem',
      margin: '0 0 1.38rem',
    },
    h2: {
      fontSize: '2.074rem',
      margin: '0 0 0.68rem',
    },
    h3: {
      fontSize: '1.728rem',
      margin: '0 0 0.68rem',
    },
    h4: {
      fontSize: '1.44rem',
      margin: '0 0 0.68rem',
    },
    h5: {
      fontSize: '1.2rem',
      margin: '0 0 0.68rem',
    },
  },
})

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      primary: {
        main: string
      }
      secondary: {
        main: string
      }
      error: {
        main: string
      }
      warning: {
        main: string
      }
      info: {
        main: string
      }
    }
  }
}
