import { useState, useCallback, FormEvent } from 'react'
import { useMutation } from 'graphql-hooks'
import { Box, Button, TextField } from '@mui/material'
import { useApp } from '../context'
import { LoginInput, LoginPayload } from '../types'

export const LoginForm = () => {
  const app = useApp()
  const [login] = useMutation<
    { login: Pick<LoginPayload, 'username'> },
    { input: LoginInput }
  >(LOG_IN)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = useCallback(
    async (e: FormEvent, input: LoginInput) => {
      e.preventDefault()
      const { data } = await login({ variables: { input } })
      if (data?.login) {
        app.setUsername(data.login.username)
        window.localStorage.setItem(
          'swingdance_user',
          JSON.stringify({ username: data.login.username })
        )
      }
    },
    [app, login]
  )

  const clearForm = useCallback(() => {
    setUsername('')
    setPassword('')
  }, [])

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      onSubmit={(e: FormEvent) => handleSubmit(e, { username, password })}
    >
      <TextField
        type="text"
        variant="filled"
        label="Username"
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <TextField
        type="password"
        variant="filled"
        label="Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 2,
        }}
      >
        <Button variant="contained" type="submit">
          Submit
        </Button>
        <Button variant="outlined" type="button" onClick={clearForm}>
          Clear
        </Button>
      </Box>
    </Box>
  )
}

const LOG_IN = `
  mutation ($input: LoginInput!) {
    login(input: $input) {
      username
      token
    }
  }
`
