import { Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PackageItemEdge, WooCommerceProduct } from '../types'

export interface ProductPackageItemsProps {
  items: Array<
    Pick<PackageItemEdge, 'qty'> & {
      node: Pick<WooCommerceProduct, 'id' | 'name' | 'sku'>
    }
  >
}

export const ProductPackageItems = ({ items }: ProductPackageItemsProps) => {
  const rows = useMemo(() => {
    return items.map(item => ({
      id: item.node.id,
      qty: item.qty,
      sku: item.node.sku,
      name: item.node.name,
    }))
  }, [items])

  const columns = useMemo(() => {
    const output: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'qty', headerName: 'Qty', width: 100 },
      { field: 'sku', headerName: 'SKU', width: 200 },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Link to={`/product/woocommerce/${params.id}`}>{params.value}</Link>
        ),
      },
    ]

    return output
  }, [])

  return (
    <>
      <Typography variant="h2" sx={{ mt: 2, mb: 0 }}>
        Contents
      </Typography>
      <div style={{ height: 400 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </>
  )
}
