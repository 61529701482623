import { useState, useEffect } from 'react'
import { Box, ImageList, ImageListItem } from '@mui/material'

export interface ImageGalleryImage {
  id: string | number
  src: string
  alt: string
}

export interface ImageGalleryProps {
  images: ImageGalleryImage[]
}

export const ImageGallery = ({ images }: ImageGalleryProps) => {
  const [selectedImage, setSelectedImage] = useState<ImageGalleryImage>()

  useEffect(() => {
    setSelectedImage(selectedImage => {
      if (!images) {
        return undefined
      }
      if (!selectedImage) {
        return images[0]
      }
      if (!images.includes(selectedImage)) {
        return images[0]
      }

      return selectedImage
    })
  }, [images])

  return (
    <>
      {!!selectedImage && (
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            margin: 'auto',
          }}
        >
          <img
            src={selectedImage.src}
            alt={selectedImage.alt}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
      )}
      {images.length > 1 && (
        <ImageList cols={4} sx={{ width: '100%' }}>
          {images.map(image => (
            <ImageListItem
              key={image.id}
              onClick={() => setSelectedImage(image)}
              sx={{
                cursor: 'pointer',
                width: '100%',
                height: 0,
                pb: '100%',
                position: 'relative',
              }}
            >
              <div
                style={{
                  background: `url('${image.src}') center center / cover`,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  )
}
