import { FormEvent, useCallback, useState } from 'react'
import { useMutation } from 'graphql-hooks'
import { Box, Button, Typography } from '@mui/material'
import {
  EndReverbProductPayload,
  ProductType,
  PublishReverbProductPayload,
  SynchronizeQuantityPayload,
  ReverbProduct,
  ReverbProductStatus,
} from '../types'

interface ReverbSimpleProductActionsProps {
  type: ProductType.Simple
  product: Pick<ReverbProduct, 'id' | 'status' | 'qtyAvailable'>
}

interface ReverbSerialProductActionsProps {
  type: ProductType.Serial
  product: Pick<ReverbProduct, 'id' | 'status'>
}

type ReverbProductActionsProps =
  | ReverbSimpleProductActionsProps
  | ReverbSerialProductActionsProps

export const ReverbProductActions = ({
  type,
  product,
}: ReverbProductActionsProps) => {
  const [status, setStatus] = useState<ReverbProductStatus>(product.status)
  const [publishProduct] = useMutation<{
    publishReverbProduct: PublishReverbProductPayload
  }>(PUBLISH_PRODUCT, {
    variables: {
      id: parseInt(product.id),
    },
  })
  const [endProduct] = useMutation<{
    endReverbProduct: EndReverbProductPayload
  }>(END_PRODUCT, {
    variables: {
      id: parseInt(product.id),
    },
  })

  const sendEndRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const { data } = await endProduct()
      if (data?.endReverbProduct.success) {
        setStatus(ReverbProductStatus.Ended)
      }
    },
    [endProduct]
  )

  const sendPublishRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const { data } = await publishProduct()
      if (data?.publishReverbProduct.success) {
        setStatus(ReverbProductStatus.Published)
      }
    },
    [publishProduct]
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {type === ProductType.Simple && (
        <ReverbProductQtyAdjustForm
          productId={parseInt(product.id)}
          initialQty={product.qtyAvailable || 0}
        />
      )}
      {status === ReverbProductStatus.Published ? (
        <form onSubmit={sendEndRequest}>
          <Button type="submit" variant="outlined" sx={{ flex: '0 2 150px' }}>
            End
          </Button>
        </form>
      ) : (
        <form onSubmit={sendPublishRequest}>
          <Button type="submit" variant="outlined" sx={{ flex: '0 2 150px' }}>
            Publish
          </Button>
        </form>
      )}
    </Box>
  )
}

export type ReverbProductQtyAdjustFormProps = {
  productId: number
  initialQty: number
}

export const ReverbProductQtyAdjustForm = ({
  productId,
  initialQty,
}: ReverbProductQtyAdjustFormProps) => {
  const [qty, setQty] = useState(initialQty)
  const [syncQty] = useMutation<
    { synchronizeQuantity: SynchronizeQuantityPayload },
    { id: number }
  >(SYNC_PRODUCT_QTY)

  const sendUpdateRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const { data } = await syncQty({ variables: { id: productId } })

      if (data?.synchronizeQuantity.success) {
        setQty(data.synchronizeQuantity.quantity as number)
      }
    },
    [productId, syncQty]
  )

  return (
    <Box
      component="form"
      onSubmit={sendUpdateRequest}
      sx={{ display: 'flex', gap: 2, alignItems: 'baseline' }}
    >
      <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
        Qty. Available: <strong>{qty}</strong>
      </Typography>
      <Button type="submit" variant="contained" sx={{ flex: '0 2 150px' }}>
        Update Qty
      </Button>
    </Box>
  )
}

const SYNC_PRODUCT_QTY = `
  mutation ($id: ID!) {
    synchronizeQuantity (
      id: $id
      origin: REVERB
    ) {
      success
      quantity
    }
  }
`

const PUBLISH_PRODUCT = `
  mutation ($id: ID!) {
    publishReverbProduct (id: $id) {
      success
    }
  }
`

const END_PRODUCT = `
  mutation ($id: ID!) {
    endReverbProduct (id: $id) {
      success
    }
  }
`
