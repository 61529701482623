import { useQuery } from 'graphql-hooks'
import { Box, Paper, Typography } from '@mui/material'
import { ProductProperty, ProductPropList } from '.'
import {
  ActiveEProduct,
  ActiveESerial,
  ActiveESimpleProduct,
  ActiveEVariableProduct,
  ActiveEVariant,
  ForeignIds,
  Log,
  ProductType,
} from '../types'
import { ProductAttributeList, ProductLinks, ProductLogList } from '.'
import { formatMoney } from '../util'

export interface ActiveEProductProps {
  id: number
}

interface ProductBase
  extends Pick<
    ActiveEProduct,
    'origin' | 'id' | 'name' | 'model' | 'category' | 'subcategory'
  > {
  foreignIds: Pick<ForeignIds, 'reverb' | 'woocommerce'>
  logs: {
    nodes: Pick<Log, 'id' | 'message' | 'origin' | 'updatedAt'>[]
  }
}

interface SimpleProduct
  extends ProductBase,
    Pick<
      ActiveESimpleProduct,
      'sku' | 'qtyAvailable' | 'regularPrice' | 'attributes'
    > {}

interface ProductVariant
  extends Pick<
    ActiveEVariant,
    'sku' | 'qtyAvailable' | 'regularPrice' | 'attributes'
  > {}

interface VariableProduct
  extends ProductBase,
    Pick<ActiveEVariableProduct, 'qtyAvailable'> {
  variants: ProductVariant[]
}

interface ProductSerial
  extends Pick<
    ActiveESerial,
    'sku' | 'serial' | 'regularPrice' | 'attributes'
  > {}

interface SerialProduct extends ProductBase {
  serials: ProductSerial[]
}

type ProductQueryProduct =
  | ({ type: ProductType.Simple } & SimpleProduct)
  | ({ type: ProductType.Variable } & VariableProduct)
  | ({ type: ProductType.Serial } & SerialProduct)

export const ActiveEProductDisplay = ({ id }: ActiveEProductProps) => {
  const { data, loading, error } = useQuery<{ product: ProductQueryProduct }>(
    GET_PRODUCT,
    {
      variables: {
        id,
      },
    }
  )

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  if (!data) {
    if (error) {
      console.log(error)
    }
    return <Typography>An error occurred.</Typography>
  }

  return (
    <>
      <Box component={Paper} sx={{ p: 4, mb: 2 }}>
        <Typography variant="h1">{data?.product.name}</Typography>
        <ProductLinks product={data.product} />
        <ProductPropList>
          {!!data.product.model && (
            <ProductProperty primary="Model" secondary={data.product.model} />
          )}
          <ProductProperty
            primary="Category"
            secondary={data.product.category.name}
          />
          <ProductProperty
            primary="Subcategory"
            secondary={data.product.subcategory.name}
          />
          {data.product.type === ProductType.Simple && (
            <>
              <ProductProperty
                primary="Regular Price"
                secondary={formatMoney(data.product.regularPrice)}
              />
              <ProductProperty
                primary="Quantity"
                secondary={data.product.qtyAvailable.toString()}
              />
            </>
          )}
        </ProductPropList>
        {data.product.type === ProductType.Simple && (
          <ProductAttributeList attributes={data.product.attributes} />
        )}
        <ProductLogList logs={data.product.logs.nodes} />
      </Box>
      {data.product.type === ProductType.Variable &&
        data.product.variants.map(variant => (
          <Box key={variant.sku} component={Paper} sx={{ p: 4, mb: 2 }}>
            <Typography variant="h2">{variant.sku}</Typography>
            <ProductProperty
              primary="Regular Price"
              secondary={formatMoney(variant.regularPrice)}
            />
            <ProductProperty
              primary="Quantity"
              secondary={variant.qtyAvailable.toString()}
            />
            <ProductAttributeList attributes={variant.attributes} />
          </Box>
        ))}
      {data.product.type === ProductType.Serial &&
        data.product.serials.map(serial => (
          <Box key={serial.serial} component={Paper} sx={{ p: 4, mb: 2 }}>
            <Typography variant="h2">{serial.serial}</Typography>
            <ProductProperty
              primary="Regular Price"
              secondary={formatMoney(serial.regularPrice)}
            />
            <ProductAttributeList attributes={serial.attributes} />
          </Box>
        ))}
    </>
  )
}

const GET_PRODUCT = `
  query ($id: ID!) {
    product (
      id: $id
      origin: ACTIVE_E
    ) {
      origin
      id
      name
      type
      model
      foreignIds {
        reverb
        woocommerce
      }
      logs {
        nodes {
          id
          message
          origin
          updatedAt
        }
      }
      ...on ActiveEProduct {
        category {
          id
          name
        }
        subcategory {
          id
          name
        }
        ...on ActiveESimpleProduct {
          sku
          qtyAvailable
          regularPrice
          attributes {
            name
            value
          }
        }
        ...on ActiveEVariableProduct {
          qtyAvailable
          variants {
            sku
            qtyAvailable
            regularPrice
            attributes {
              name
              value
            }
          }
        }
        ...on ActiveESerialProduct {
          serials {
            sku
            serial
            regularPrice
            attributes {
              name
              value
            }
          }
        }
      }
    }
  }
`
