export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type ActiveECategory = {
  __typename?: 'ActiveECategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ActiveEProduct = {
  category: ActiveECategory;
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  logs: ProductLogConnection;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  origin: ProductOrigin;
  sku?: Maybe<Scalars['String']>;
  subcategory: ActiveECategory;
  type: ProductType;
};


export type ActiveEProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type ActiveEProductAttribute = {
  __typename?: 'ActiveEProductAttribute';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ActiveESerial = {
  __typename?: 'ActiveESerial';
  attributes: Array<ActiveEProductAttribute>;
  regularPrice: Scalars['Int'];
  serial: Scalars['String'];
  sku: Scalars['String'];
};

export type ActiveESerialProduct = ActiveEProduct & Product & {
  __typename?: 'ActiveESerialProduct';
  category: ActiveECategory;
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  logs: ProductLogConnection;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  origin: ProductOrigin;
  serials: Array<ActiveESerial>;
  sku?: Maybe<Scalars['String']>;
  subcategory: ActiveECategory;
  type: ProductType;
};


export type ActiveESerialProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type ActiveESimpleProduct = ActiveEProduct & Product & {
  __typename?: 'ActiveESimpleProduct';
  attributes: Array<ActiveEProductAttribute>;
  category: ActiveECategory;
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  logs: ProductLogConnection;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  origin: ProductOrigin;
  qtyAvailable: Scalars['Float'];
  regularPrice: Scalars['Int'];
  sku?: Maybe<Scalars['String']>;
  subcategory: ActiveECategory;
  type: ProductType;
};


export type ActiveESimpleProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type ActiveEVariableProduct = ActiveEProduct & Product & {
  __typename?: 'ActiveEVariableProduct';
  category: ActiveECategory;
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  logs: ProductLogConnection;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  origin: ProductOrigin;
  qtyAvailable: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  subcategory: ActiveECategory;
  type: ProductType;
  variants: Array<ActiveEVariant>;
};


export type ActiveEVariableProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type ActiveEVariant = {
  __typename?: 'ActiveEVariant';
  attributes: Array<ActiveEProductAttribute>;
  qtyAvailable: Scalars['Float'];
  regularPrice: Scalars['Int'];
  sku: Scalars['String'];
};

export type EndReverbProductPayload = {
  __typename?: 'EndReverbProductPayload';
  success: Scalars['Boolean'];
};

export type ForeignIds = {
  __typename?: 'ForeignIds';
  activeE?: Maybe<Scalars['String']>;
  reverb?: Maybe<Scalars['String']>;
  woocommerce?: Maybe<Scalars['String']>;
};

export type Log = {
  __typename?: 'Log';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  origin: ProductOrigin;
  updatedAt: Scalars['DateTime'];
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  token: Scalars['String'];
  username: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  endReverbProduct: EndReverbProductPayload;
  login: LoginPayload;
  publishReverbProduct: PublishReverbProductPayload;
  publishWooCommerceProduct: PublishWooCommerceProductPayload;
  synchronizeQuantity: SynchronizeQuantityPayload;
  unpublishWooCommerceProduct: UnpublishWooCommerceProductPayload;
  updateReverbProduct: UpdateReverbProductPayload;
  updateWooCommerceProduct: UpdateWooCommerceProductPayload;
};


export type MutationEndReverbProductArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPublishReverbProductArgs = {
  id: Scalars['ID'];
};


export type MutationPublishWooCommerceProductArgs = {
  id: Scalars['ID'];
  variantId?: InputMaybe<Scalars['ID']>;
};


export type MutationSynchronizeQuantityArgs = {
  id: Scalars['ID'];
  origin: ProductOrigin;
  variantId?: InputMaybe<Scalars['ID']>;
};


export type MutationUnpublishWooCommerceProductArgs = {
  id: Scalars['ID'];
  variantId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateReverbProductArgs = {
  input: UpdateReverbProductInput;
};


export type MutationUpdateWooCommerceProductArgs = {
  input: UpdateWooCommerceProductInput;
};

export type PackageItemConnection = {
  __typename?: 'PackageItemConnection';
  edges: Array<PackageItemEdge>;
  nodes: Array<Product>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PackageItemEdge = {
  __typename?: 'PackageItemEdge';
  cursor: Scalars['String'];
  node: Product;
  qty: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PageQuery = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type Product = {
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  logs: ProductLogConnection;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  origin: ProductOrigin;
  sku?: Maybe<Scalars['String']>;
  type: ProductType;
};


export type ProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export enum ProductIdType {
  Numeric = 'NUMERIC',
  String = 'STRING'
}

export type ProductLogConnection = {
  __typename?: 'ProductLogConnection';
  edges: Array<ProductLogEdge>;
  nodes: Array<Log>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProductLogEdge = {
  __typename?: 'ProductLogEdge';
  cursor: Scalars['String'];
  node: Log;
};

export enum ProductOrigin {
  ActiveE = 'ACTIVE_E',
  Ebay = 'EBAY',
  Reverb = 'REVERB',
  Woocommerce = 'WOOCOMMERCE'
}

export enum ProductType {
  Package = 'PACKAGE',
  Serial = 'SERIAL',
  Simple = 'SIMPLE',
  Variable = 'VARIABLE'
}

export type PublishReverbProductPayload = {
  __typename?: 'PublishReverbProductPayload';
  success: Scalars['Boolean'];
};

export type PublishWooCommerceProductPayload = {
  __typename?: 'PublishWooCommerceProductPayload';
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  product?: Maybe<Product>;
  products: Array<Product>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<ProductIdType>;
  origin: ProductOrigin;
};


export type QueryProductsArgs = {
  origin: ProductOrigin;
  page?: InputMaybe<PageQuery>;
  term?: InputMaybe<Scalars['String']>;
};

export type ReverbProduct = {
  buyerPrice?: Maybe<Scalars['Int']>;
  categories: Array<ReverbProductCategory>;
  condition: ReverbProductCondition;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  finish: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<ReverbProductImage>;
  logs: ProductLogConnection;
  make: Scalars['String'];
  manageStock: Scalars['Boolean'];
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offersEnabled: Scalars['Boolean'];
  origin: ProductOrigin;
  price: Scalars['Int'];
  publishedAt: Scalars['DateTime'];
  qtyAvailable: Scalars['Int'];
  sellerCost?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  status: ReverbProductStatus;
  type: ProductType;
  url: Scalars['String'];
  year: Scalars['String'];
};


export type ReverbProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type ReverbProductCategory = {
  __typename?: 'ReverbProductCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ReverbProductCondition = {
  __typename?: 'ReverbProductCondition';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ReverbProductImage = {
  __typename?: 'ReverbProductImage';
  full: Scalars['String'];
  large: Scalars['String'];
  small: Scalars['String'];
  thumbnail: Scalars['String'];
};

export enum ReverbProductStatus {
  Draft = 'DRAFT',
  Ended = 'ENDED',
  Published = 'PUBLISHED'
}

export type ReverbSerialProduct = Product & ReverbProduct & {
  __typename?: 'ReverbSerialProduct';
  buyerPrice?: Maybe<Scalars['Int']>;
  categories: Array<ReverbProductCategory>;
  condition: ReverbProductCondition;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  finish: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<ReverbProductImage>;
  logs: ProductLogConnection;
  make: Scalars['String'];
  manageStock: Scalars['Boolean'];
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offersEnabled: Scalars['Boolean'];
  origin: ProductOrigin;
  price: Scalars['Int'];
  publishedAt: Scalars['DateTime'];
  qtyAvailable: Scalars['Int'];
  sellerCost?: Maybe<Scalars['Int']>;
  serial: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  status: ReverbProductStatus;
  type: ProductType;
  url: Scalars['String'];
  year: Scalars['String'];
};


export type ReverbSerialProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type ReverbSimpleProduct = Product & ReverbProduct & {
  __typename?: 'ReverbSimpleProduct';
  buyerPrice?: Maybe<Scalars['Int']>;
  categories: Array<ReverbProductCategory>;
  condition: ReverbProductCondition;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  finish: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<ReverbProductImage>;
  logs: ProductLogConnection;
  make: Scalars['String'];
  manageStock: Scalars['Boolean'];
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offersEnabled: Scalars['Boolean'];
  origin: ProductOrigin;
  price: Scalars['Int'];
  publishedAt: Scalars['DateTime'];
  qtyAvailable: Scalars['Int'];
  sellerCost?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  status: ReverbProductStatus;
  type: ProductType;
  url: Scalars['String'];
  year: Scalars['String'];
};


export type ReverbSimpleProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type SynchronizeQuantityPayload = {
  __typename?: 'SynchronizeQuantityPayload';
  quantity?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type UnpublishWooCommerceProductPayload = {
  __typename?: 'UnpublishWooCommerceProductPayload';
  success: Scalars['Boolean'];
};

export type UpdateReverbProductInput = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  condition?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exclusiveChannel?: InputMaybe<Scalars['String']>;
  finish?: InputMaybe<Scalars['String']>;
  hasInventory?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inventory?: InputMaybe<Scalars['Int']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  offersEnabled?: InputMaybe<Scalars['Boolean']>;
  originCountryCode?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  price?: InputMaybe<Scalars['Int']>;
  prop65Warning?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  sellerCost?: InputMaybe<Scalars['String']>;
  shippingProfileId?: InputMaybe<Scalars['String']>;
  shippingProfileName?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  soldAsIs?: InputMaybe<Scalars['Boolean']>;
  storageLocation?: InputMaybe<Scalars['String']>;
  taxExempt?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  upc?: InputMaybe<Scalars['String']>;
  upcDoesNotApply?: InputMaybe<Scalars['Boolean']>;
  videos?: InputMaybe<Array<Scalars['String']>>;
  year?: InputMaybe<Scalars['String']>;
};

export type UpdateReverbProductPayload = {
  __typename?: 'UpdateReverbProductPayload';
  product?: Maybe<ReverbProduct>;
  success: Scalars['Boolean'];
};

export type UpdateWooCommerceProductInput = {
  dateOnSaleFrom?: InputMaybe<Scalars['DateTime']>;
  dateOnSaleTo?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  manageStock?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  regularPrice?: InputMaybe<Scalars['String']>;
  reviewsAllowed?: InputMaybe<Scalars['Boolean']>;
  salePrice?: InputMaybe<Scalars['String']>;
  shippingClass?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WooCommerceProductStatus>;
  stockQuantity?: InputMaybe<Scalars['Int']>;
  stockStatus?: InputMaybe<WooCommerceProductStockStatus>;
  type?: InputMaybe<ProductType>;
  virtual?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateWooCommerceProductPayload = {
  __typename?: 'UpdateWooCommerceProductPayload';
  product?: Maybe<WooCommerceProduct>;
  success: Scalars['Boolean'];
};

export type WooCommercePackageProduct = Product & WooCommerceProduct & {
  __typename?: 'WooCommercePackageProduct';
  attributes: Array<WooCommerceProductAttribute>;
  categories: Array<WooCommerceProductCategory>;
  children: PackageItemConnection;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<WooCommerceProductImage>;
  logs: ProductLogConnection;
  manageStock: Scalars['Boolean'];
  manufacturer?: Maybe<Scalars['String']>;
  metaData: Array<WordPressMetaData>;
  model?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  origin: ProductOrigin;
  price: Scalars['Int'];
  purchasable: Scalars['Boolean'];
  qtyAvailable?: Maybe<Scalars['Int']>;
  regularPrice: Scalars['Int'];
  salePrice?: Maybe<Scalars['Int']>;
  shippingClass?: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: WooCommerceProductStatus;
  stockStatus: WooCommerceProductStockStatus;
  tags: Array<WooCommerceProductTag>;
  type: ProductType;
  url: Scalars['String'];
};


export type WooCommercePackageProductChildrenArgs = {
  page?: InputMaybe<PageQuery>;
};


export type WooCommercePackageProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type WooCommerceProduct = {
  attributes: Array<WooCommerceProductAttribute>;
  categories: Array<WooCommerceProductCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<WooCommerceProductImage>;
  logs: ProductLogConnection;
  manageStock: Scalars['Boolean'];
  manufacturer?: Maybe<Scalars['String']>;
  metaData: Array<WordPressMetaData>;
  model?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  origin: ProductOrigin;
  qtyAvailable?: Maybe<Scalars['Int']>;
  shippingClass?: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: WooCommerceProductStatus;
  stockStatus: WooCommerceProductStockStatus;
  tags: Array<WooCommerceProductTag>;
  type: ProductType;
  url: Scalars['String'];
};


export type WooCommerceProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type WooCommerceProductAttribute = {
  __typename?: 'WooCommerceProductAttribute';
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<Scalars['String']>;
  position: Scalars['Int'];
  variation: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type WooCommerceProductCategory = {
  __typename?: 'WooCommerceProductCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type WooCommerceProductImage = {
  __typename?: 'WooCommerceProductImage';
  alt: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  src: Scalars['String'];
};

export enum WooCommerceProductStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Private = 'PRIVATE',
  Published = 'PUBLISHED'
}

export enum WooCommerceProductStockStatus {
  InStock = 'IN_STOCK',
  OnBackorder = 'ON_BACKORDER',
  OutOfStock = 'OUT_OF_STOCK'
}

export type WooCommerceProductTag = {
  __typename?: 'WooCommerceProductTag';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type WooCommerceSerialProduct = Product & WooCommerceProduct & {
  __typename?: 'WooCommerceSerialProduct';
  attributes: Array<WooCommerceProductAttribute>;
  categories: Array<WooCommerceProductCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<WooCommerceProductImage>;
  logs: ProductLogConnection;
  manageStock: Scalars['Boolean'];
  manufacturer?: Maybe<Scalars['String']>;
  metaData: Array<WordPressMetaData>;
  model?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  origin: ProductOrigin;
  price: Scalars['Int'];
  purchasable: Scalars['Boolean'];
  qtyAvailable?: Maybe<Scalars['Int']>;
  regularPrice: Scalars['Int'];
  salePrice?: Maybe<Scalars['Int']>;
  serial: Scalars['String'];
  shippingClass?: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: WooCommerceProductStatus;
  stockStatus: WooCommerceProductStockStatus;
  tags: Array<WooCommerceProductTag>;
  type: ProductType;
  url: Scalars['String'];
};


export type WooCommerceSerialProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type WooCommerceSimpleProduct = Product & WooCommerceProduct & {
  __typename?: 'WooCommerceSimpleProduct';
  attributes: Array<WooCommerceProductAttribute>;
  categories: Array<WooCommerceProductCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<WooCommerceProductImage>;
  logs: ProductLogConnection;
  manageStock: Scalars['Boolean'];
  manufacturer?: Maybe<Scalars['String']>;
  metaData: Array<WordPressMetaData>;
  model?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  origin: ProductOrigin;
  price: Scalars['Int'];
  purchasable: Scalars['Boolean'];
  qtyAvailable?: Maybe<Scalars['Int']>;
  regularPrice: Scalars['Int'];
  salePrice?: Maybe<Scalars['Int']>;
  shippingClass?: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: WooCommerceProductStatus;
  stockStatus: WooCommerceProductStockStatus;
  tags: Array<WooCommerceProductTag>;
  type: ProductType;
  url: Scalars['String'];
};


export type WooCommerceSimpleProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type WooCommerceVariableProduct = Product & WooCommerceProduct & {
  __typename?: 'WooCommerceVariableProduct';
  attributes: Array<WooCommerceProductAttribute>;
  categories: Array<WooCommerceProductCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<WooCommerceProductImage>;
  logs: ProductLogConnection;
  manageStock: Scalars['Boolean'];
  manufacturer?: Maybe<Scalars['String']>;
  metaData: Array<WordPressMetaData>;
  model?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  origin: ProductOrigin;
  qtyAvailable?: Maybe<Scalars['Int']>;
  shippingClass?: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: WooCommerceProductStatus;
  stockStatus: WooCommerceProductStockStatus;
  tags: Array<WooCommerceProductTag>;
  type: ProductType;
  url: Scalars['String'];
  variants: Array<WooCommerceVariant>;
};


export type WooCommerceVariableProductLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type WooCommerceVariant = Product & {
  __typename?: 'WooCommerceVariant';
  attributes: Array<WooCommerceVariantAttribute>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  foreignIds: ForeignIds;
  id: Scalars['ID'];
  images: Array<WooCommerceProductImage>;
  logs: ProductLogConnection;
  manageStock: Scalars['Boolean'];
  metaData: Array<WordPressMetaData>;
  model?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  origin: ProductOrigin;
  price: Scalars['Int'];
  purchasable: Scalars['Boolean'];
  qtyAvailable?: Maybe<Scalars['Int']>;
  regularPrice: Scalars['Int'];
  salePrice?: Maybe<Scalars['Int']>;
  shippingClass?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  status: WooCommerceProductStatus;
  stockStatus: WooCommerceProductStockStatus;
  type: ProductType;
  url: Scalars['String'];
};


export type WooCommerceVariantLogsArgs = {
  page?: InputMaybe<PageQuery>;
};

export type WooCommerceVariantAttribute = {
  __typename?: 'WooCommerceVariantAttribute';
  id: Scalars['ID'];
  name: Scalars['String'];
  option: Scalars['String'];
};

export type WordPressMetaData = {
  __typename?: 'WordPressMetaData';
  id: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
};
