import { Box } from '@mui/material'
import { LoginForm } from '../components'

export const Login = () => {
  return (
    <Box sx={{ maxWidth: 450, mt: 10, mx: 'auto' }}>
      <LoginForm />
    </Box>
  )
}
