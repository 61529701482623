import { Typography } from '@mui/material'
import { ProductProperty, ProductPropList } from '.'

export interface ProductAttributeListProps {
  attributes: {
    name: string
    value: string
  }[]
}

export const ProductAttributeList = ({
  attributes,
}: ProductAttributeListProps) => {
  if (!attributes.length) return null

  return (
    <>
      <Typography variant="h2" sx={{ mt: 2, mb: 0 }}>
        Attributes
      </Typography>
      <ProductPropList>
        {attributes.map(attribute => (
          <ProductProperty
            key={attribute.name}
            primary={attribute.name}
            secondary={attribute.value}
          />
        ))}
      </ProductPropList>
    </>
  )
}
