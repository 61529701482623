import { ListItem, ListItemText } from '@mui/material'

export interface ProductPropertyProps {
  primary: string
  secondary: string
}

export const ProductProperty = (props: ProductPropertyProps) => {
  return (
    <ListItem sx={{ width: 'auto', flex: '0 1 33%' }}>
      <ListItemText {...props} />
    </ListItem>
  )
}
