import { Routes, Route } from 'react-router-dom'
import { useApp } from '../context'
import { Layout } from '../components'
import { ComingSoon, Login, Products, SingleProduct } from '../routes'

export const Router = () => {
  const { username } = useApp()

  return (
    <Routes>
      <Route element={<Layout />}>
        {username ? (
          <>
            <Route index element={<Products />} />
            <Route path="/product/:platform/:id" element={<SingleProduct />} />
            <Route path="/products" element={<Products />} />
            <Route path="/settings" element={<ComingSoon />} />
            <Route path="/status" element={<ComingSoon />} />
          </>
        ) : (
          <Route path="*" element={<Login />} />
        )}
      </Route>
    </Routes>
  )
}
